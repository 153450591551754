import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Trans, useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

const EmployeeSatisfaction = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  return (
    <Layout>
      <Seo title={t("Employee Satisfaction")} />
      <div className="p-5 gap-5 d-flex flex-column">
        <div
          className="container border shadow p-5 bg-white"
          style={{
            borderRadius: "1rem",
          }}
        >
          <h1 className="bg-warning p-4 text-dark text-center rounded shadow">
            <Trans>Employee Satisfaction</Trans>
          </h1>
          <div className="row pt-5 ">
            {/* <h4 className="text-center">
              <Trans>Guidelines for setting up</Trans>
            </h4> */}
            <hr className="dropdown-divider"></hr>
            <div
              className="col-sm-12 col-lg-6 justify-content-center
              d-flex flex-wrap align-content-center"
            >
              <StaticImage
                src="../images/istockphoto-1226928825-612x612.jpg"
                quality={95}
                // width={"auto"}
                formats={["auto", "webp", "avif"]}
                alt="Employee Satisfaction"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div
              className="col-sm-12 col-lg-6 d-flex flex-column
            justify-content-center"
            >
              <p className="pt-5">
                <Trans>
                  uhappy? is a useful tool to understand the level of
                  satisfaction of your employees. It also helps to evaluate
                  their training needs as shown in the examples followed
                </Trans>
              </p>
              <div className="d-flex justify-content-center pt-5 flex-wrap gap-5 gap-md-3 w-100">
                <div className="d-flex flex-column align-items-center">
                  <span className="text-muted">
                    <Trans>scan the qr code</Trans>
                  </span>
                  {language === "en" ? (
                    <StaticImage
                      src="../images/es1 en.png"
                      quality={95}
                      // width={"auto"}
                      formats={["auto", "webp", "avif, png"]}
                      alt="QR 1"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/es1 gr.png"
                      quality={95}
                      // width={"auto"}
                      formats={["auto", "webp", "avif, png"]}
                      alt="QR 1"
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <span className="text-muted">
                    <Trans>or</Trans>
                  </span>
                  <Link to={t("es1")}>
                    <button className="btn btn-dark btn-lg shadow my-4">
                      <Trans>Example</Trans> {" 1"}
                    </button>
                  </Link>
                  <span className="text-muted">
                    <Trans>click on the button</Trans>
                  </span>
                </div>

                <div className="d-flex flex-column align-items-center">
                  <span className="text-muted">
                    <Trans>scan the qr code</Trans>
                  </span>
                  {language === "en" ? (
                    <StaticImage
                      src="../images/es2 en.png"
                      quality={95}
                      // width={"auto"}
                      formats={["auto", "webp", "avif, png"]}
                      alt="QR 2"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/es2 gr.png"
                      quality={95}
                      // width={"auto"}
                      formats={["auto", "webp", "avif, png"]}
                      alt="QR 2"
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <span className="text-muted">
                    <Trans>or</Trans>
                  </span>
                  <Link to={t("es2")}>
                    <button className="btn btn-dark btn-lg shadow my-4">
                      <Trans>Example</Trans> {" 2"}
                    </button>
                  </Link>
                  <span className="text-muted">
                    <Trans>click on the button</Trans>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmployeeSatisfaction

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
